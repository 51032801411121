<app-i-advize *ngIf="clientProfile || dataLoaded" [pageType]="ProfileStepName.MembershipCheck" [clientProfile]="clientProfile"></app-i-advize>
<app-kameleoon></app-kameleoon>

<div class="text-center mt-5">
  <h5 *ngIf="!isPeri" [innerHtml]="'Home.Title' | labelText"></h5>
  <h5 *ngIf="isPeri" [innerHtml]="'Home.Title2' | labelText"></h5>
</div>

<!-- loginComplited: {{ !!registrationForm }} <br />
registrationForm: {{ !!registrationForm }}<br />
isContract: {{ !!isContract }}<br />
expandContract: {{ !!expandContract }}<br />
showForm: {{ !!showForm }}<br /> -->

<div *ngIf="loginComplited">
  <div class="card">
    <div class="card-body">
      <h5>{{ 'HOME.AlreadyClientTitle' | labelText }}</h5>
      <div class="buttons-block mb-4">
        <button class="btn btn-primary radio-label mr-2" type="button" [ngClass]="{ 'active-btn': showForm && isContract }" (click)="isContractChanged(true)">{{ 'Common.Yes' | labelText }}</button>
        <button class="btn btn-primary radio-label" type="button" [ngClass]="{ 'active-btn': showForm && !isContract }" (click)="isContractChanged(false)">{{ 'Common.No' | labelText }}</button>
      </div>
      <p class="txt-default">{{ 'HOME.QuestionAlreadyClientHelp' | labelText }}</p>
    </div>
  </div>
</div>

<div *ngIf="loginComplited" [ngClass]="{ hidden: !showForm }">
  <div class="card oui-card">
    <div class="card-body">
      <div *ngIf="isContract && !expandContract">
        <p class="mb-2">Merci de vous authentifier à l’aide de vos codes d’accès personnel MIF</p>
        <p class="sign-error mb-4" *ngIf="signInErrorMessage">
          {{ signInErrorMessage }}
        </p>
        <div class="row">
          <div class="col text-center">
            <button type="button" class="btn btn-blue valider goToSignInGreen" (click)="goToSignInGreen()">Me connecter</button>
          </div>
        </div>
      </div>

      <!-- REGISTRATIONN FORM -->

      <div *ngIf="!isContract || (isContract && expandContract)">
        <form [formGroup]="registrationForm" *ngIf="registrationForm">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
              <app-combobox-form-control [parentForm]="registrationForm" [controlName]="'civilityCode'" [title]="'Common.YourCivility' | labelText"> </app-combobox-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
              <app-name-form-control [parentForm]="registrationForm" [controlName]="'lastName'" [title]="'Common.SpouseName' | labelText"> </app-name-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
              <app-name-form-control [parentForm]="registrationForm" [controlName]="'firstName'" [title]="'Common.FirstName' | labelText"> </app-name-form-control>
            </div>
          </div>

          <div class="row" *ngIf="isPeri">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
              <app-date-form-control
                [parentForm]="registrationForm"
                [controlName]="'expectedRetirementDate'"
                [title]="'Common.ExpectedRetirementDate' | labelText"
                [minDate]="expectedRetirementMinDate"
              >
              </app-date-form-control>
            </div>
          </div>

          <div class="row" *ngIf="isPeri">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
              <app-number-form-control [parentForm]="registrationForm" [controlName]="'retirementYearsCount'" [title]="'Home.RetirementYearsCountTitle' | labelText"> </app-number-form-control>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5">
              <app-date-form-control
                [parentForm]="registrationForm"
                [controlName]="'dateOfBirth'"
                [title]="'Common.DateOfBirth' | labelText"
                [maxDate]="dobMaxDate"
                [ngClass]="{ wtihManualError: dobRegValueIsNotInRangeLimit }"
              >
              </app-date-form-control>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="dobRegValueIsNotInRangeLimit">
              <div class="error-block">
                <span class="text"> {{ dobErrorBlockText }} </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-5">
              <app-email-form-control [parentForm]="registrationForm" [controlName]="'email'" [title]="'Common.Email' | labelText" (focusout)="emailChange($event)"> </app-email-form-control>
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 txt-default after-input">{{ 'HOME.AbleToResumeAtAnyTime' | labelText }}</div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <app-mobile-form-control class="error-new-row" [parentForm]="registrationForm" [controlName]="'mobilePhone'" [title]="'Common.MobileNumber' | labelText"> </app-mobile-form-control>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <app-phone-form-control class="error-new-row" [parentForm]="registrationForm" [controlName]="'phone'" [title]="'Common.PhoneNumber' | labelText"> </app-phone-form-control>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 txt-default after-input">{{ 'HOME.MobilePhoneIsMandatory' | labelText }}</div>
          </div>

          <div class="row mt-5" *ngIf="!isContract">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
              <app-checkbox-form-control class="big-text mt-0" [title]="'Home.OKToReceivePromotionalOffersByEmail' | labelText" [parentForm]="registrationForm" [controlName]="'receiveEmail'">
              </app-checkbox-form-control>
            </div>
          </div>

          <div class="row" *ngIf="!isContract">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
              <app-checkbox-form-control class="big-text mt-0" [title]="'Home.OKToReceivePromotionalOffersByText' | labelText" [parentForm]="registrationForm" [controlName]="'receiveSms'">
              </app-checkbox-form-control>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="warning">
      {{ 'HOME.GatherDocumentsToFinalizeSubcription' | labelText }}
    </div>

    <!-- HOW DID YOU KNOW ABOUT MIF FORM -->

    <div *ngIf="!isContract || (isContract && expandContract)">
      <form [formGroup]="mifForm" *ngIf="mifForm" class="hide-in-pdf">
        <div class="mt-4">
          <div class="card">
            <div class="row">
              <div class="col-12 text-left">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h5 *ngIf="!isContract" class="header mt-3">{{ 'SUMMARY.FormReferer' | labelText }}</h5>
                      <h5 *ngIf="isContract" class="header mt-3">{{ 'SUMMARY.FormRefererInCaseMember' | labelText }}</h5>
                    </div>
                  </div>

                  <app-view-offer
                    [clientProfile]="clientProfileForViewOffer"
                    [pageName]="constants.pages.summary.name"
                    [firstPage]="true"
                    (onOffersItem)="onOffersItem($event)"
                    (onOffersItems)="onOffersItems($event)"
                    [manualOverrideOffer]="manualOverrideOffer"
                  ></app-view-offer>

                  <div class="row" [ngClass]="{ hidden: isContract && expandContract }">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                      <app-dropdown-form-control [parentForm]="mifForm" (onChange)="onSourceChange($event)" [controlName]="'advertisementSourceCode'" [title]="'SUMMARY.FormReferer' | labelText">
                      </app-dropdown-form-control>
                    </div>
                  </div>

                  <div class="row" *ngIf="source === sources.PARRAINAGE">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                      <app-number-form-control [parentForm]="mifForm" [controlName]="'sponsorNumber'" [title]="'SUMMARY.SponsorNumberTitle' | labelText"> </app-number-form-control>
                    </div>
                  </div>

                  <div class="row" *ngIf="source === sources.PARTENAIRE">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                      <app-number-form-control [parentForm]="mifForm" [controlName]="'partnerCode'" [title]="'SUMMARY.PartnerCode' | labelText"> </app-number-form-control>
                    </div>
                  </div>

                  <div class="row" *ngIf="source === sources.MEMBRE_CORRESPONDANT">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                      <app-number-form-control [parentForm]="mifForm" [controlName]="'memberNumber'" [title]="'SUMMARY.MemberNumberTitle' | labelText"> </app-number-form-control>
                    </div>
                  </div>

                  <div class="row" *ngIf="source === sources.MEMBRE_CORRESPONDANT">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-3">
                      <app-name-form-control [parentForm]="mifForm" [controlName]="'lastName'" [title]="'SUMMARY.MemberFullNameTitle' | labelText"> </app-name-form-control>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                      <app-name-form-control [parentForm]="mifForm" [controlName]="'firstName'" [title]="''"> </app-name-form-control>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xs-12 col-sm-9 col-md-9 col-lg-6">
                      <app-name-with-digits-form-control [parentForm]="mifForm" [controlName]="'promoCode'" [title]="'SUMMARY.CodePromoTitle' | labelText"> </app-name-with-digits-form-control>
                    </div>
                  </div>

                  <div class="row mt-4" *ngIf="advertisementSourceCodeControl.value !== sources.MEMBRE_CORRESPONDANT">
                    <div class="col-xs-12 col-sm-10 col-md-7 col-lg-7">
                      {{ 'SUMMARY.FormAdvisorCode' | labelText }}
                    </div>
                  </div>

                  <div class="row mb-2" *ngIf="advertisementSourceCodeControl.value !== sources.MEMBRE_CORRESPONDANT">
                    <div class="col-xs-12 col-sm-9 col-md-6 col-lg-3">
                      <app-number-form-control class="no-title" [parentForm]="mifForm" [controlName]="'mifAdvisorCode'" [title]="''"> </app-number-form-control>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="row mt-5 mb-5" [ngClass]="{ hidden: !showForm }" *ngIf="!isContract || (isContract && expandContract)">
      <div class="col-12 text-center" *ngIf="registrationForm">
        <button type="submit" class="btn btn-blue valider" [disabled]="!isRegistrationFormValid || mifForm.invalid" (click)="onSubmitRegistration()">
          {{ 'Common.Validate' | labelText }}
        </button>
      </div>
    </div>

    <!-- <div class="row mt-5" [ngClass]="{ hidden: !showForm }" *ngIf="isContract">
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-blue valider" *ngIf="isAuthValidateVisible" [disabled]="clientChecking || mifForm.invalid">
          {{ clientChecking ? ('Home.InProgress' | labelText) : ('Common.Validate' | labelText) }}
        </button>
      </div>
    </div> -->
  </div>
</div>
