import { Component, ViewChild, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as version from '../../mif/environments/versions';
import { MifAuthService } from 'projects/mif/app/wizard/welcome/auth/mif-auth.service';

@Component({
  selector: 'app-root-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  constructor(private mifAuthService: MifAuthService) {}

  @ViewChild('sidenav') sidenav: MatSidenav;
  isShowing = false;
  isLoggedIn: boolean;

  ngOnInit(): void {
    this.mifAuthService.isAuthenticated().subscribe(isAuthenticated => {
      this.isLoggedIn = isAuthenticated;
    });
    console.log('version: ' + JSON.stringify(version));
  }

  mouseenter(): void {
    this.isShowing = true;
  }

  mouseleave(): void {
    this.isShowing = false;
  }
}
