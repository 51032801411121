import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { MifAuthService } from 'projects/mif/app/wizard/welcome/auth/mif-auth.service';

@Injectable()
export class AdminAuthInterceptor implements HttpInterceptor {

  constructor(private mifAuthService: MifAuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.mifAuthService.getAccessToken()
      .pipe(mergeMap(token => this.processRequestWithToken(token, request, next)));
  }

  private processRequestWithToken(token: string | null, req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!!token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }

}
