export const ApplicationName = 'mif.admin';
export const ReturnUrlType = 'returnUrl';
export const QueryParameterNames = {
  ReturnUrl: ReturnUrlType,
  Message: 'message'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

let adminApplicationPaths: ApplicationPathsType = {
  DefaultLoginRedirectPath: '/home',
  Login: `authentication/${LoginActions.Login}`,
  LoginCallback: `authentication/${LoginActions.LoginCallback}`,
  LogOut: `authentication/${LogoutActions.Logout}`,
  LoggedOut: `authentication/${LogoutActions.LoggedOut}`,
  LogOutCallback: `authentication/${LogoutActions.LogoutCallback}`,
};

adminApplicationPaths = {
  ...adminApplicationPaths,
};

interface ApplicationPathsType {
  readonly DefaultLoginRedirectPath: string;
  readonly Login: string;
  readonly LoginCallback: string;
  readonly LogOut: string;
  readonly LoggedOut: string;
  readonly LogOutCallback: string;
}

export const AdminApplicationPaths: ApplicationPathsType = adminApplicationPaths;
