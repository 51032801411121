import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AdminApplicationPaths, QueryParameterNames } from './admin-api-authorization.constants';
import { MifAuthService } from 'projects/mif/app/wizard/welcome/auth/mif-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private mifAuthService: MifAuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.mifAuthService.isAuthenticated().pipe(tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state)));
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot): void {
    if (!isAuthenticated) {
      this.router.navigate([AdminApplicationPaths.Login], {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }
}
