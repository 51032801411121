export const constants = {
  fundsVisibleDuringLimitedPeriod: [
    {
      name: 'MIF Structure Rendement 1',
      isin: 'XS2481716939',
      startDisplayDate: '2022-10-03', // include this date YYYY-MM-DD
      endDisplayDate: '2022-11-30' // include this date YYYY-MM-DD
    },
    {
      name: 'MIF Structure Rendement 2 FR001400E953',
      isin: 'ATT01',
      startDisplayDate: '2022-12-12', // include this date YYYY-MM-DD
      endDisplayDate: '2023-03-12' // include this date YYYY-MM-DD
    },
    {
      name: 'MIF Structure Rendement 2',
      isin: 'FR001400E953',
      startDisplayDate: '2022-12-12', // include this date YYYY-MM-DD
      endDisplayDate: '2023-03-12' // include this date YYYY-MM-DD
    }
  ],
  clientNumberATokenKey: 'name',
  codeMadame: 'Madame',
  codeMonsieur: 'Monsieur',
  yesLibelle: 'Oui',
  cimsPrimaryBeneficiaryMinAge: 18,
  cimsContractPrimaryBeneficiaryMaxAge: 26,
  docTypeIdToRetriveFromRecommendation: 8,
  gestionLibre: { id: 1, name: 'Gestion Libre' },
  gestionSousMandat: { id: 2, name: 'Gestion Sous Mandat' },
  gestionHorizon: { id: 3, name: 'Gestion à Horizon' },
  links: {
    footerDocIdName: `Note d'information`,
    termsTransferOfContractDocIdName: `Modalités du transfert de contrat`,
    termsTransferOfContractDocId: 12,
    periSecurityProfileBtnLink: 'https://www.mifassur.com/assurance-vie-multisupport/compte-epargne-libre-avenir-multisupport'
  },
  extraDocumentsForGpaSubscriptionOfferIds: [138, 38], // Document d'Information Produit relatif à la garantie invalidité-décès, Note d'Information valant Règlement mutualiste des contrats
  fundsRuleTypeId: 3,
  maxNegativeNumber: -999999, // -Number.MAX_VALUE - use this one in case maxNegativeNumber is not enough 
  overwriterRecommendationTabDefaultSelection: {
    defaultTabSelected: 'Gestion Libre', // Possible values: Gestion Libre, Gestion Sous Mandat, Gestion à Horizon
    profileTypeId: 2, // Prudent
    startDate: '2024-01-24', // start date included (format: YYYY-MM-DD)
    endDate: '2024-06-20' // end date included (format: YYYY-MM-DD)
  },
  localStorageKeys: {
    tempHealthQuestionnaireForm: 'TempHealthQuestionnaireForm',
    tempClauseNominativeForm: 'TempClauseNominativeForm',
    tempClauseNominativeFormIsEqual: 'TempClauseNominativeFormIsEqual',
    tempClauseLibreForm: 'TempClauseLibreForm',
    tempReuseBeneficiaries: 'TempReuseBeneficiaries',
    tempGpaBeneficiareType: 'TempGpaBeneficiareType'
  },
  inCaseNoCemsCimsRule27_28LimitValue: 100,
  getBicFromIbanServiceUnavailableCode: 999,
  validatePhoneServiceUnavailableCode: 999,
  clauseNominativeBeneficiaryMaxAge: 125,
  orgId: {
    cems: { value: 'cems' },
    cims: { value: 'cims' },
    gpa: { value: 'gpa' },
    gfd: { value: 'gfd' },
    peri: { value: 'peri' },
    admin: { value: 'admin' }
  },
  maxLength: {
    firstName: 25,
    lastName: 30,
    birthName: 30,
    ville: 32,
    nDeVoieLieuDit: 38,
    complementAdresse: 38,
    locality: 38
  },
  codes: {
    blockedProfile: 'Profile is blocked',
    notFound: 'Not found',
    wrongCode: 'INCORRECT_OTP_CODE',
    invalidOtp: 'Invalid OTP'
  },
  nomCommercialRuleName: 'FONDS EN EUROS MIF',
  identifiantCreancierSEPA: 'FR78ZZZ318027',
  sponsorshipCode: 'PARRAINAGE',
  homePage: 'membership-check',
  residencePage: 'residence-questions',
  shortDateFormat: 'DD-MM-YYYY',
  dateInputFormat: 'DD/MM/YYYY',
  datePipeFormat: 'dd/MM/yyyy',
  configDateFormat: 'YYYY-MM-DD',
  dateInputFormatWithTime: 'DD/MM/YYYY HH:mm',
  autoSpinnerDelay: 300, // ms
  asyncValidationDebounceTime: 500, // ms
  asyncMobileValidationDebounceTime: 1, // ms
  euroFondId: 1,
  beneficiaresTypes: {
    standard: { name: 'standard', code: 0 },
    nominative: { name: 'nominative', code: 1 },
    libre: { name: 'libre', code: 2 }
  },
  beneficiaresTypesGfd: {
    funeralOperator: { name: 'funeralOperator', code: 0 },
    naturalPerson: { name: 'naturalPerson', code: 1 }
  },
  userTypes: {
    prospect: { name: 'prospect', code: 1 }, // not customer yet
    memberWhoDoesNotAlreadyHaveGPAContract: { name: 'memberWhoDoesNotAlreadyHaveGPAContract', code: 2 }
  },
  agesScores: {
    to50: { maxAge: 49, points: 7 },
    from50to60: { minAge: 50, maxAge: 59, points: 5 },
    from60to75: { minAge: 60, maxAge: 74, points: 3 },
    from75: { minAge: 75, points: 0 }
  },
  offerStatuses: {
    disabledId: 1,
    activeId: 2,
    activeByTimeId: 3
  },
  sectionsSortOrder: ['PAYMENTS', 'CAPITAL', 'RECOMMENDATION', 'DISTRIBUTION', 'PAYMENTS_REMINDER', 'ADDRESS', 'SUMMARY', 'EMAIL_FIRST_NOTICE', 'EMAIL_SECOND_NOTICE'],
  conditionTypes: {
    userType: {
      id: 1,
      code: 'UserType'
    },
    userAge: {
      id: 2,
      code: 'UserAge',
      min: {
        code: 'UserAge.Min'
      },
      max: {
        code: 'UserAge.Max'
      }
    },
    subscriptionAge: {
      id: 4,
      code: 'SubscriptionAge',
      min: {
        code: 'SubscriptionAge.Min'
      },
      max: {
        code: 'SubscriptionAge.Max'
      }
    },
    savingsSecurityProfile: {
      id: 6,
      code: 'SavingsSecurityProfile'
    },
    savingsUCDistribtuion: {
      id: 7,
      code: 'savingsUCDistribtuion'
    },
    savingsTransferPact: {
      id: 8,
      code: 'savingsTransferPact'
    },
    savingsInitialDeposit: {
      id: 9,
      code: 'savingsInitialDeposit'
    },
    pensionCapitalGuarantee: {
      id: 10,
      code: 'pensionCapitalGuarantee'
    },
    pensionCoveragex2x3: {
      id: 11,
      code: 'pensionCoveragex2x3'
    },
    sponsorship: {
      id: 12,
      code: 'sponsorship'
    },
    partnerCode: {
      id: 13,
      code: 'partnerCode'
    }
  },
  pages: {
    recommendation: { name: 'RECOMMENDATION' },
    payments: { name: 'PAYMENTS' }, // Projet de versement
    paymentsReminder: { name: 'PAYMENTS_REMINDER' }, // rappelVersement
    distribution: { name: 'DISTRIBUTION' },
    capital: { name: 'CAPITAL' },
    address: { name: 'ADDRESS' },
    summary: { name: 'SUMMARY' }
  },
  productNames: {
    cems: { id: 1, name: 'CEMS', fullName: 'Compte Épargne Libre Avenir Multisupport' },
    gpa: { id: 2, name: 'GPA', fullName: 'Garantie Protection Avenir' },
    gfd: { id: 3, name: 'GFD', fullName: 'Garantie Frais Décès' },
    peri: { id: 4, name: 'PERI', fullName: 'MIF PER Retraite' },
    cims: { id: 5, name: 'CIMS', fullName: 'MIF Épargne Enfant' }
  },
  productNamesArray: [
    { id: 1, name: 'CEMS', fullName: 'Compte Épargne Libre Avenir Multisupport' },
    { id: 2, name: 'GPA', fullName: 'Garantie Protection Avenir' },
    { id: 3, name: 'GFD', fullName: 'Garantie Frais Décès' },
    { id: 4, name: 'PERI', fullName: 'MIF PER Retraite' },
    { id: 5, name: 'CIMS', fullName: 'MIF Épargne Enfant' }
  ],
  periPoches: {
    serenite: {
      name: 'Poche serenité'
    },
    performance: {
      name: 'Poche performance'
    }
  },
  ageTypes: {
    day: { id: 1 },
    hour: { id: 2 },
    min: { id: 3 }
  },
  footerLinksTypes: {
    url: 'URL',
    document: 'DOCUMENT'
  },
  mCivilityCodeId: 99,
  identifiantDemarche: 33,
  showExtraFinancialTableAmount: 61000,
  showExtraFinancialTableAmountWithAnswer: 45000,
  sécuritaire: { name: 'Sécuritaire', color: '#189bd5' },
  sécuritaire10: { name: 'Sécuritaire', color: '#189bd5' },
  sécuritaire35: { name: 'Sécuritaire', color: '#189bd5' },
  prudent: { id: 1, name: 'Prudent', color: '#7ecf27' },
  equilibré: { id: 2, name: 'Équilibré', color: '#facf3a' },
  dynamique: { id: 3, name: 'Dynamique', color: '#e8773e' },
  offensif: { id: 4, name: 'Offensif', color: '#e83e3e' },
  securitaireProfileLess40KId: 10, // amountVerse < 40000 (and/or transferPACTE true)
  securitaireProfileMore40KId: 35,
  securitaireProfileCims: 51,
  sécuritaireOtherColor: '#CF557A',
  promoCode: 'PB32',
  validStatus: 'VALID',
  invalidStatus: 'INVALID',
  referenceDescriptionField: 'value',
  informationValueField: 'libelleLong',
  referenceKeyField: 'id',
  reference2DescriptionField: 'libelleLong',
  reference2KeyField: 'id',
  countryDescriptionField: 'langFR',
  countryKeyField: 'id',
  tooltipHideDelay: 500,
  fileUploaderMaxFileSize: 2097152, // File size in bytes
  fileUploaderSupportedTypes: ['image/jpeg', 'image/png', 'application/pdf'], // http://www.iana.org/assignments/media-types/media-types.xhtml
  yesAnswer: 'yes',
  yesAnswerId: 0,
  noAnswer: 'no',
  noAnswerId: 1,
  sncfAswer: 'SNCF',
  ratpAswer: 'RATP',
  otherAnswer: 'other',
  mariedAnswer: 'Marié',
  notCommunicatedAnswer: 'Non communiqué',
  mademoiselleAnswer: 'Mademoiselle',
  othersAnswer: 'Autres',
  otherAnswerFR: 'Autre',
  gfdPrivilegiers: {
    financial: {
      name: 'Une solution de financement et d`organisation',
      code: 0
    },
    financialAndOrganisation: {
      name: 'Une solution de financement uniquement',
      code: 1
    }
  },
  steps: {
    myProfile: {
      name: 'myProfile',
      countOfTheSteps: 12
    },
    myContracts: {
      myRate: {
        initialStep: 17
      },
      name: 'myContracts',
      countOfTheSteps: 20
    },
    myBeneficiaries: {
      name: 'myBeneficiaries',
      countOfTheSteps: 30,
      gfdInitialStep: 25,
      nominative: {
        name: 'nominative',
        initialStep: 25
      },
      libre: {
        name: 'libre',
        initialStep: 25
      },
      contract: {
        name: 'contract',
        initialStep: 27
      }
    },
    myDetails: {
      name: 'myDetails',
      countOfTheSteps: 40,
      personal: {
        name: 'personal',
        initialStep: 30
      },
      contacts: {
        name: 'contacts',
        initialStep: 32
      },
      financial: {
        name: 'financial',
        initialStep: 35
      },
      bankDetails: {
        name: 'bankDetails',
        initialStep: 37
      }
    },
    signature: {
      name: 'signature',
      initialStep: 44,
      countOfTheSteps: 50,
      phoneConfirmation: {
        initialStep: 46
      }
    }
  }
};
