import { constants } from '../../../shared/constants/constants';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetAllPaysRequest, GetAllPaysResponse, GetAllReferenceRequest, GetAllReferenceResponse, GetClientByNumeroClientARequest, GetClientByNumeroClientAResponse, GetIdentite, Pays, Client, Informations, GetMotifsCSPRequest, GetMotifsCSPResponse, CSP, Motifs, GetMotifsPPEResponse, GetMotifsPPERequest, Professions, Sousmotifs } from '../MIF.Subscription.Parrot';

@Injectable({
  providedIn: 'root'
})
export class WsCRMClientService {
  private allReferences: Informations[];
  private allCountries: Pays[];

  private nationalities: Informations[];
  private civilities: Informations[];
  private familySituations: Informations[];
  private matrimonialRegimes: Informations[];
  private socioProfessionalCategories: CSP[];
  private politicallyPersonTypes: Motifs[];
  private industries: Informations[];
  private netMonthlyIncomes: Informations[];
  private professionalSituations: Informations[];
  private originsOfWealth: Informations[];

  private skipedFamilySituations: any[] = [constants.notCommunicatedAnswer];
  private skipedCivilities: any[] = [constants.mademoiselleAnswer];
  private skipedMatrimonialRegimes: any[] = [constants.notCommunicatedAnswer];
  private skipedSocioProfessionalCategories: any[] = [];
  private skipedIndustries: any[] = [constants.notCommunicatedAnswer];
  private skipedNetMonthlyIncomes: any[] = [constants.notCommunicatedAnswer];
  private skipedProfessionalSituations: any[] = [constants.notCommunicatedAnswer];
  private skipedOriginsOfWealth: any[] = [constants.notCommunicatedAnswer, constants.othersAnswer];

  public skippedItemsData: any = {};

  private clientsData: any = {};

  constructor(private webApi: Client) { }

  public async initialize(): Promise<void> {
    try {
      await this.getAllInformations().then(() => {
        this.initializeSkippedItemsData();
      })
    } catch (err) {
      console.error(err);
    }
  }

  public async getAllCountries(): Promise<any> {
    if (this.allCountries) {
      return new Promise(resolve => {
        resolve(this.allCountries);
      })
    } else {
      const request = new GetAllPaysRequest();

      return this.webApi.getAllPays(request).pipe(
        map((response: GetAllPaysResponse) => {
          this.allCountries = response.return.filter((item: Pays) => {
            return item.alpha2 !== 'ZZ';
          });
          this.allCountries = this.allCountries?.sort((a: Pays, b: Pays) => (a.langFR < b.langFR) ? -1 : ((b.langFR > a.langFR) ? 1 : 0));

          //const countriesDataJson: any = countries; // uncomment it to see missed counties in static json file
          // let missed = [];
          // this.allCountries.forEach((item: Pays) => {
          //   if (countriesDataJson[item.alpha3] === undefined) {
          //     missed.push(item);
          //   }
          // });
          // debugger

          return this.allCountries;
        })
      ).toPromise();
    }
  }

  public async getNationalities(): Promise<any> {
    return this.getSmtFromInformations(this.nationalities, InformationsType.NATIONALITY);
  }

  public async getCivilities(): Promise<any> {
    return this.getSmtFromInformations(this.civilities, InformationsType.CIVILITY, this.skipedCivilities);
  }

  public async getFamilySituations(): Promise<any> {
    return this.getSmtFromInformations(this.familySituations, InformationsType.FAMILY_SITUATION, this.skipedFamilySituations);
  }

  public async getMatrimonialRegimes(): Promise<any> {
    return this.getSmtFromInformations(this.matrimonialRegimes, InformationsType.MATRIMONIAL_REGIME, this.skipedMatrimonialRegimes);
  }

  public async getSocioProfessionalCategories(): Promise<any> {
    if (this.socioProfessionalCategories) {
      return new Promise(resolve => {
        resolve(this.socioProfessionalCategories);
      })
    } else {
      const request = new GetMotifsCSPRequest();
      return this.webApi.getMotifsCSP(request).pipe(
        map((response: GetMotifsCSPResponse) => {
          this.socioProfessionalCategories = response.CSP.filter((csp: CSP) => {
            return csp.actif === true;
          });

          this.socioProfessionalCategories = this.socioProfessionalCategories?.sort((a: CSP, b: CSP) => (a.ordre > b.ordre) ? 1 : ((b.ordre < a.ordre) ? -1 : 0));

          this.socioProfessionalCategories?.forEach((csp: CSP) => {
            csp.professions = csp.professions?.filter((prof: Professions) => {
              return prof.actif === true;
            })
          })

          if (this.skipedSocioProfessionalCategories) {
            this.skipedSocioProfessionalCategories?.forEach((value: any) => {
              this.socioProfessionalCategories = this.socioProfessionalCategories.filter((item: CSP) => {
                return item.libelle !== value;
              })
            })
          }
          return this.socioProfessionalCategories;
        })
      ).toPromise();
    }
  }

  public async getPoliticallyPersonTypes(): Promise<any> {
    if (this.politicallyPersonTypes) {
      return new Promise(resolve => {
        resolve(this.politicallyPersonTypes);
      })
    } else {
      const request = new GetMotifsPPERequest();

      return this.webApi.getMotifsPPE(request).pipe(
        map((response: GetMotifsPPEResponse) => {
          this.politicallyPersonTypes = response.motifs?.sort((a: Motifs, b: Motifs) => (a.ordre > b.ordre) ? 1 : ((b.ordre > a.ordre) ? -1 : 0))
            .map((item: Motifs) => {
              item.sousmotifs.sort((a: Sousmotifs, b: Sousmotifs) => (a.ordre > b.ordre) ? 1 : ((b.ordre > a.ordre) ? -1 : 0))
              return item;
            });
          return this.politicallyPersonTypes;
        })
      ).toPromise();
    }
  }

  public async getIndustries(): Promise<any> {
    return this.getSmtFromInformations(this.industries, InformationsType.INDUSTRY, this.skipedIndustries);
  }

  public async getNetMonthlyIncomes(): Promise<any> {
    return this.getSmtFromInformations(this.netMonthlyIncomes, InformationsType.NET_MONTHLY_INCOME, this.skipedNetMonthlyIncomes);
  }

  public async getNetMonthlyIncomesPersonal(): Promise<any> {
    return this.getSmtFromInformations(this.netMonthlyIncomes, InformationsType.NET_MONTHLY_INCOME_PERSONAL, this.skipedNetMonthlyIncomes);
  }

  public async getProfessionalSituations(): Promise<any> {
    return this.getSmtFromInformations(this.professionalSituations, InformationsType.PROF_SITUATION, this.skipedProfessionalSituations);
  }

  public async getOriginsOfWealth(): Promise<any> {
    return this.getSmtFromInformations(this.originsOfWealth, InformationsType.ORIGIN_OF_WEALTH, this.skipedOriginsOfWealth);
  }

  public async getOriginsOfWealthOtherId(): Promise<any> {
    let originOfWealthOtherId = 0;
    let originsOfWealth = await this.getSmtFromInformations(this.originsOfWealth, InformationsType.ORIGIN_OF_WEALTH, []) as Informations[];

    originsOfWealth.forEach((item: Informations) => {
      if (item.libelleLong === constants.othersAnswer) {
        originOfWealthOtherId = item.id;
      }
    })
    return originOfWealthOtherId;
  }

  // public async getClientByNumberA(numberA: string) {
  //   const response = await this.getClientByNumeroClientA(numberA);

  //   return new Promise<GetIdentite>(resolve => {
  //     resolve(response);
  //   })
  // }

  // private async getClientByNumeroClientA(numeroClientA: string): Promise<GetIdentite> {
  //   if (this.clientsData[numeroClientA]) {
  //     return new Promise(resolve => {
  //       resolve(this.clientsData[numeroClientA]);
  //     })
  //   } else {
  //     const request = new GetClientByNumeroClientARequest({
  //       numeroClientA: numeroClientA
  //     });

  //     return this.webApi.getClientByNumeroClientA(request).pipe(
  //       map((response: GetClientByNumeroClientAResponse) => {
  //         this.clientsData[numeroClientA] = response.return;
  //         return this.clientsData[numeroClientA];
  //       })
  //     ).toPromise();
  //   }
  // }

  private async getAllInformations(): Promise<any> {
    if (this.allReferences) {
      return new Promise(resolve => {
        resolve(this.allReferences);
      })
    } else {
      const request = new GetAllReferenceRequest();

      return this.webApi.getAllReference(request).pipe(
        map((response: GetAllReferenceResponse) => {
          this.allReferences = response.informations;
          this.allReferences = this.allReferences?.sort((a: Informations, b: Informations) => (a.libelleLong < b.libelleLong) ? -1 : ((b.libelleLong > a.libelleLong) ? 1 : 0));
          return this.allReferences;
        })
      ).toPromise();
    }
  }

  private initializeSkippedItemsData(): void {
    this.pricessSkippedData(InformationsType.CIVILITY, this.skipedCivilities);
    this.pricessSkippedData(InformationsType.FAMILY_SITUATION, this.skipedFamilySituations);
    this.pricessSkippedData(InformationsType.MATRIMONIAL_REGIME, this.skipedMatrimonialRegimes);
    this.pricessSkippedData(InformationsType.INDUSTRY, this.skipedIndustries);
    this.pricessSkippedData(InformationsType.NET_MONTHLY_INCOME, this.skipedNetMonthlyIncomes);
    this.pricessSkippedData(InformationsType.NET_MONTHLY_INCOME_PERSONAL, this.skipedNetMonthlyIncomes);
    this.pricessSkippedData(InformationsType.PROF_SITUATION, this.skipedProfessionalSituations);
    this.pricessSkippedData(InformationsType.ORIGIN_OF_WEALTH, this.skipedOriginsOfWealth);
  }

  pricessSkippedData(type: InformationsType, toBeSkippedItems?: any[]): void {
    if (toBeSkippedItems && this.allReferences) {
      toBeSkippedItems.forEach((value: any) => {
        this.skippedItemsData[type] = this.allReferences
          .filter((item: Informations) => {
            return item.type?.toString().trim() === type;
          })
          .filter((item: Informations) => {
            return item.libelleLong === value;
          })
      })
    }
  }

  private async getSmtFromInformations(smt: any, smtType: InformationsType, skip?: any[]) {
    if (smt) {
      return new Promise(resolve => {
        resolve(smt);
      })
    }

    await this.getAllInformations();

    smt = this.allReferences.filter((item: Informations) => {
      return item.type?.toString().trim() === smtType;
    })

    if (skip) {
      skip.forEach((value: any) => {
        smt = smt.filter((item: Informations) => {
          return item.libelleLong !== value;
        })
      })
    }

    if (smtType === InformationsType.NATIONALITY) {
      smt = smt?.sort((a: Informations, b: Informations) => (a.ordre > b.ordre) ? 1 : ((b.ordre > a.ordre) ? -1 : 0));
    } else {
      smt = smt?.sort((a: Informations, b: Informations) => (a.libelleLong < b.libelleLong) ? -1 : ((b.libelleLong > a.libelleLong) ? 1 : 0));
    }

    return new Promise(resolve => {
      resolve(smt);
    })
  }

}

export enum InformationsType {
  CIVILITY = 'TITRE',
  NATIONALITY = 'NATIONALITE',
  FAMILY_SITUATION = 'SITUATION_FAMILIALE',
  PROF_SITUATION = 'SITUATION_PROFESSIONNELLE',
  MATRIMONIAL_REGIME = 'REGIME_MATRIMONIAL',
  INDUSTRY = 'SECTEUR_ACTIVITE',
  ORIGIN_OF_WEALTH = 'COMPOSITION_PATRIMOINE',
  //NET_MONTHLY_INCOME = 'SITUATION_PATRIMONIALE',
  NET_MONTHLY_INCOME = 'SITUATION_FINANCIERE',
  NET_MONTHLY_INCOME_PERSONAL = 'SITUATION_FINANCIERE',
}
